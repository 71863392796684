import { type HttpClient } from '@/application/interfaces/http-client';

import getCategoriesTree from './get-categories-tree';
import getSubcategoryChildren from './get-subcategory-children';

const CategoriesService = (httpClient: HttpClient.Instance) => ({
  getCategoriesTree: getCategoriesTree(httpClient),
  getSubcategoryChildren: getSubcategoryChildren(httpClient),
});

export default CategoriesService;
