import { type ServiceMethod } from '@/application/interfaces/service';

import { type ModularContent } from './model';

type Body = {
  userId?: string;
  products: Array<{
    id: number;
    trackingUrl?: string;
  }>;
};

export type Response = {
  data: ModularContent.Product[];
};

const getRecommendedProductsData: ServiceMethod<Response, Body> =
  (httpClient) => async (data, options) => {
    const { body } = await httpClient.post<
      Response,
      Body,
      undefined,
      undefined
    >('/api/v3/modularContents/products', {
      ...options,
      body: data,
    });

    return body;
  };

export default getRecommendedProductsData;
