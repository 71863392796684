import { type ServiceMethod } from '@/application/interfaces/service';

export interface Response {
  data: {
    products: number[];
    contents: string[];
  };
}

const getWishlistsItemsIds: ServiceMethod<Response> =
  (httpClient) => async (options) => {
    const { body } = await httpClient.get<Response, undefined, undefined>(
      `/api/v3/wishlists/ids`,
      {
        ...options,
      }
    );

    return body;
  };

export default getWishlistsItemsIds;
