import { httpClient } from '../http/fetch';

import * as Catalog from './catalog';
import CategoriesService from './categories';
import * as Checkout from './checkout';
import LocationService from './location';
import ModularContentService from './modular-content';
import NewTailService from './new-tail';
import NewsletterService from './newsletter';
import RegionService from './region';
import RichRelevanceService from './rich-relevance';
import UserService from './user';
import UserContextService from './user-context';
import VouchersService from './vouchers';
import WishlistService from './wishlist';

export const Services = {
  Catalog: {
    Product: Catalog.Product(httpClient),
    Review: Catalog.Review(httpClient),
    Service: Catalog.Service(httpClient),
  },
  Categories: CategoriesService(httpClient),
  Checkout: {
    Cart: Checkout.Cart(httpClient),
  },
  Location: LocationService(httpClient),
  ModularContent: ModularContentService(httpClient),
  NewTail: NewTailService(httpClient),
  Newsletter: NewsletterService(httpClient),
  Region: RegionService(httpClient),
  RichRelevance: RichRelevanceService(httpClient),
  User: UserService(httpClient),
  UserContext: UserContextService(httpClient),
  Vouchers: VouchersService(httpClient),
  Wishlist: WishlistService(httpClient),
};
