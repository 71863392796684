import { type ServiceMethod } from '@/application/interfaces/service';
import { type ItemId } from '@/infra/services/catalog/model';

import { type Product } from './model';

export type Params = {
  itemId: ItemId;
};

export type Response = {
  data: {
    product: Product;
  };
};

const getProductInfo: ServiceMethod<Response, Params> =
  (httpClient) => async (data, options) => {
    const { itemId } = data;

    const { body: response } = await httpClient.get<Response, Params>(
      '/api/v3/products/{itemId}',
      {
        ...options,
        params: {
          itemId,
        },
      }
    );

    return response;
  };

export default getProductInfo;
