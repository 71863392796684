import { isBrowser } from '@backyard-ui/utils';

import { type HttpClient } from '@/application/interfaces/http-client';
import {
  AB_TESTING,
  API_TOKEN,
  CART_ID,
  CART_ITEMS_QUANTITY,
  IS_ON_MOBILE_APP,
  LOCATION_SOURCE,
  REGION,
  TOTEM,
} from '@/utils/constants/cookies';
import {
  AB_TESTING_HEADER,
  AUTHORIZATION_HEADER,
  MOBILE_APP_HEADER,
  REGION_HEADER,
  TOTEM_HEADER,
} from '@/utils/constants/headers';
import { cookies } from '@/utils/cookies';

import { addPreviewTrueQuery } from './modifiers/add-preview-true-query';

const COOKIES_TO_HEADER_MAP = {
  [AB_TESTING]: AB_TESTING_HEADER,
  [IS_ON_MOBILE_APP]: MOBILE_APP_HEADER,
  [REGION]: REGION_HEADER,
  [TOTEM]: TOTEM_HEADER,
  [API_TOKEN]: AUTHORIZATION_HEADER,
};

const COOKIES_TO_OVERRIDE = [
  REGION,
  LOCATION_SOURCE,
  CART_ID,
  CART_ITEMS_QUANTITY,
];

type CookiesToHeaderMapKey = keyof typeof COOKIES_TO_HEADER_MAP;

const tryToOverrideHttpOnlyCookies = async () => {
  if (!isBrowser) return {};

  const cookiesFromClient = cookies.client.getMany(COOKIES_TO_OVERRIDE);

  const missingCookies = cookiesFromClient.reduce<string[]>((acc, cookie) => {
    if (!cookie.value) acc.push(cookie.name);
    return acc;
  }, []);

  if (missingCookies.length === 0) return {};

  const cookiesFromServer = await cookies.server.getMany(missingCookies);

  const cookiesOptions = [];
  const cookiesValues: Record<string, any> = {};

  for (const cookie of cookiesFromServer) {
    if (!cookie.value) continue;

    const cookieOption = cookies.createOptions({
      name: cookie.name,
      value: cookie.value,
    });

    cookiesOptions.push(cookieOption);
    cookiesValues[cookie.name] = cookie.value;
  }

  if (cookiesOptions.length > 0) {
    await cookies.server.setMany(cookiesOptions);
  }

  return cookiesValues;
};

const requestInterceptor: HttpClient.RequestInterceptor = async (options) => {
  options.headers = options.headers || {};

  const getCookies = isBrowser
    ? cookies.client.getMany
    : cookies.server.getMany;

  const cookiesToGet = Object.keys(
    COOKIES_TO_HEADER_MAP
  ) as CookiesToHeaderMapKey[];

  const cookiesArray = await getCookies<CookiesToHeaderMapKey>(cookiesToGet);

  for (const cookieObject of cookiesArray) {
    const headerName = COOKIES_TO_HEADER_MAP[cookieObject.name];

    if (cookieObject.value) {
      const value =
        cookieObject.name === API_TOKEN
          ? `Bearer ${cookieObject.value}`
          : cookieObject.value;

      options.headers[headerName] = value;
    }
  }

  /**
   * Para conseguirmos pegar alguns cookies pelo client, é preciso que eles tenham httpOnly = false.
   * Antigamente esses cookies eram httpOnly = true. Esse trecho do código reescreve os cookies caso httpOnly = true.
   * O middleware também faz isso, mas quando o usuário entra na página cacheada pela Cloudflare,
   * ele não é executado e precisamos forçar aqui novamente.
   */
  const overriddenCookies = await tryToOverrideHttpOnlyCookies();

  if (overriddenCookies[REGION]) {
    options.headers[REGION_HEADER] = overriddenCookies[REGION];
  }

  await addPreviewTrueQuery(options);

  return options;
};

export default requestInterceptor;
