import { type ServiceMethod } from '@/application/interfaces/service';

type Params = {
  slug: string;
};

export type Response = {
  data: string[];
};

const getRecommendedProductsPlacements: ServiceMethod<Response, Params> =
  (httpClient) => async (data, options) => {
    const { body } = await httpClient.get<Response, Params, undefined>(
      `/api/v3/modularContents/{slug}/placements`,
      {
        ...options,
        params: {
          slug: data.slug,
        },
      }
    );

    return body;
  };

export default getRecommendedProductsPlacements;
