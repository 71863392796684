import { type ServiceMethod } from '@/application/interfaces/service';

import { type ModularContent } from './model';

type SearchParams = {
  userId?: string;
};

type Params = {
  contentId?: string;
  moduleId?: string;
};

type Data = SearchParams & Params;

export type RemoteVoucherBasicData = ModularContent.VouchersData;

export type Response = {
  data: ModularContent.VouchersData[];
};

const getVouchersData: ServiceMethod<Response, Data> =
  (httpClient) => async (data, options) => {
    const { body } = await httpClient.get<Response, Params, SearchParams>(
      '/api/v3/modularContents/vouchers/{contentId}/{moduleId}',
      {
        ...options,
        params: {
          contentId: data.contentId,
          moduleId: data.moduleId,
        },
        searchParams: { userId: data.userId },
      }
    );

    return body;
  };

export default getVouchersData;
