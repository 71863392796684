import { type ServiceMethod } from '@/application/interfaces/service';

import { type ModularContent } from './model';

type Params = {
  slug: string;
};

type SearchParams = {
  page: number;
};

export type Response = {
  meta: {
    page: number;
    perPage: number;
    pages: number;
    count: number;
  };
  data: {
    results: ModularContent.Module[];
    title: string;
  };
};

type Data = Params & SearchParams;

const getModules: ServiceMethod<Response, Data> =
  (httpClient) => async (data, options) => {
    const { body } = await httpClient.get<Response, Params, SearchParams>(
      `/api/v3/modularContents/{slug}/modules`,
      {
        ...options,
        searchParams: {
          page: data.page,
        },
        params: {
          slug: data.slug,
        },
      }
    );

    return body;
  };

export default getModules;
