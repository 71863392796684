import { type HttpClient } from '@/application/interfaces/http-client';

import { getBreadcrumb } from './get-breadcumb';
import { getProductInfo } from './get-product-info';

const ProductService = (httpClient: HttpClient.Instance) => ({
  getProductInfo: getProductInfo(httpClient),
  getBreadcrumb: getBreadcrumb(httpClient),
});

export default ProductService;
