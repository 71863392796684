import { type OpenGraphType } from 'next/dist/lib/metadata/types/opengraph-types';

import { type ServiceMethod } from '@/application/interfaces/service';

type Params = {
  slug: string;
};

export type Response = {
  data: {
    id: string;
    title: string;
    canonicalUrl: string;
    description: string;
    pageType: string;
    openGraph: {
      title: string;
      description?: string;
      image?: string;
      url: string;
      type: OpenGraphType;
    };
    gaTrack: string;
    friendlyCategory: string;
  };
};

const getMetadata: ServiceMethod<Response, Params> =
  (httpClient) => async (data, options) => {
    const { body } = await httpClient.get<Response, Params>(
      `/api/v3/modularContents/{slug}/metadata`,
      {
        ...options,
        params: {
          slug: data.slug,
        },
      }
    );

    return body;
  };

export default getMetadata;
